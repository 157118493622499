import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: './layout/layout.module#LayoutModule',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Home' },
  },
  {
    path: 'login',
    loadChildren: './layout/login/login.module#LoginModule',
  },
  {
    path: 'recuperacao-senha',
    loadChildren: './layout/recuperacao-senha/recuperacao-senha.module#RecuperacaoSenhaModule',
  },
  {
    path: 'nova-senha',
    loadChildren: './layout/nova-senha/nova-senha.module#NovaSenhaModule',
  },
  {
    path: 'confirmacao-email-usuario',
    loadChildren: './layout/confirmacao-email-usuario/confirmacao-email-usuario.module#ConfirmacaoEmailUsuarioModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
