import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot) {
    if (localStorage.getItem('isLoggedin')) {
      const roles = next.data['permittedRoles'] as string[];
      if (roles) {
        if (this.authService.roleMatch(roles)) {
          return true;
        } else {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
